import React from 'react';
import { Col, PageHeader, Row, Input } from 'antd';
import '../../index.css';
import { ErrorMessage, FormikValues } from 'formik';
import ErrorComponent from '../../../../components/ErrorMessage';

function InputForm({
  values,
  setFieldTouched,
  setFieldValue,
}: {
  values: FormikValues;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) {
  return (
    <>
      <PageHeader className="site-page-header" subTitle="Datos personales" />
      <Row gutter={[20, 20]}>
        <Col span={6}>
          Nombre
          <Input
            // value={values.firstName}
            disabled
            // onBlur={() => setFieldTouched('firstName')}
            // onChange={(e) => setFieldValue('firstName', e.target.value)}
            placeholder="Nombre"
          />
          <ErrorMessage component={ErrorComponent} name="firstName" />
        </Col>
        <Col span={6}>
          Apellido:
          <Input
            // value={values.lastName}
            disabled
            // onBlur={() => setFieldTouched('lastName')}
            // onChange={(e) => setFieldValue('lastName', e.target.value)}
            placeholder="Apellido"
          />
          <ErrorMessage component={ErrorComponent} name="lastName" />
        </Col>
        <Col span={12}>
          Usuario:
          <Input
            value={values.email}
            onBlur={() => setFieldTouched('email')}
            onChange={(e) => setFieldValue('email', e.target.value)}
            placeholder="E-mail"
          />
          <ErrorMessage component={ErrorComponent} name="email" />
        </Col>
        <Col span={12}>
          Descripción:
          <Input
            value={values.description}
            onBlur={() => setFieldTouched('description')}
            onChange={(e) => setFieldValue('description', e.target.value)}
            placeholder="Descripción"
          />
          <ErrorMessage component={ErrorComponent} name="description" />
        </Col>
        <Col span={12}>
          Teléfono:
          <Input
            value={values.phone}
            onBlur={() => setFieldTouched('phone')}
            onChange={(e) => setFieldValue('phone', e.target.value)}
            placeholder="Teléfono"
          />
        </Col>
      </Row>
    </>
  );
}

export default InputForm;
