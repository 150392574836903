import React from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import LoginPage from './routes/login';
import './App.less';
import './index.css';
import PrivateRoute from './routes/PrivateRoute';
import AppRouter from './routes/admin';
import CollaboratorConfirm from './routes/admin/collaborator/CollaboratorConfirm';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route
            path="/admin/collaborator/confirm/:uuid"
            component={CollaboratorConfirm}
          />
          <PrivateRoute path="/admin" component={AppRouter} />
          <Redirect to="/admin" />
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
