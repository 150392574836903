const URL = `${process.env.REACT_APP_API_URL}/api`;

export const API: Readonly<any> = {
  login: `${URL}/auth/login`,
  refresh: `${URL}/auth/refresh`,
  transactions: `${URL}/users/transactions`,
  withdrawals: `${URL}/users/transactions/withdrawals`,
  collects: `${URL}/users/transactions/collects`,
  payments: `${URL}/users/transactions/payments`,
  qrGenerate: `${URL}/users/transactions/payments/emvqr`,
  collaborator: `${URL}/users/collaborator`,
  administrator: `${URL}/users/administrator`,
  accounts: `${URL}/users/merchant/cbus`,
  profile: `/profile`,
  photo: `/photo`,
  persons: `${URL}/persons`,
};

export enum ROLE {
  ADMINISTRATOR = 'Administrator',
  COLLABORATOR = 'Collaborator',
  MERCHANT = 'Merchant',
  USER = 'User',
}

export enum TRANSACTION_TYPES {
  WITHDRAWAL = 'WITHDRAWAL',
  INTERNAL = 'INTERNAL',
  INTERNAL_CASH = 'INTERNAL_CASH',
  DEPOSIT = 'DEPOSIT',
}
