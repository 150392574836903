/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import api from './axios';
import { actions } from '../redux/auth';
import { API, ROLE } from '../constants/defaultValues';
import logout from '../redux/logout';
import { Collaborator } from '../routes/admin/collaborator/CollaboratorAdd';
import { PartialCollaborator } from '../routes/admin/collaborator/components/ConfirmUserForm';

const refreshAccessToken = async (
  store: () => {
    (): any;
    new (): any;
    getState: {
      (): { auth: { tokens: { refresh_token: any }; email: any } };
      new (): any;
    };
  }
) => {
  const {
    auth: {
      tokens: { refresh_token },
      email,
    },
  } = store().getState();
  const response = await api.post(API.refresh, {
    email,
    refresh_token,
  });
  return response.data;
};

const setToken = (token: string) => {
  delete api.apiAxios.defaults.headers.common.Authorization;
  api.apiAxios.defaults.headers.common.Authorization = `bearer ${token}`;
};

const createApi = (
  store: () => {
    (): any;
    new (): any;
    getState: { (): { (): any; new (): any; auth: any }; new (): any };
    dispatch: { (arg0: { payload: any; type: string }): void; new (): any };
  }
) => {
  api.apiAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if (api.apiAxios.defaults.headers.common.Authorization) {
          try {
            const { access_token, refresh_token } = await refreshAccessToken(
              store
            );
            const {
              auth: { email },
            } = store().getState();
            store().dispatch(
              actions.loginRefresh({
                email,
                access_token,
                refresh_token,
              })
            );
            setToken(access_token);
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            return api.apiAxios(originalRequest);
          } catch (err) {
            console.log(JSON.stringify(err));
            store().dispatch(logout());
          }
        } else {
          const stateToken = store().getState()?.auth?.tokens?.access_token;
          setToken(stateToken);
          originalRequest.headers.Authorization = `Bearer ${stateToken}`;
          return api.apiAxios(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
};

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response = await api.post(API.login, {
    username: email,
    password,
  });
  setToken(response.data.access_token);
  return response;
};

const getUrlByRole = (role: ROLE) => {
  switch (role) {
    case ROLE.ADMINISTRATOR:
      return API.administrator;
    case ROLE.COLLABORATOR:
      return API.collaborator;
    default:
      return '';
  }
};

const addCollaborator = (collaborator: Collaborator) =>
  api.post(API.collaborator, collaborator);

const fetchCollaboratorEmailByToken = (token: string) =>
  api.get(`${API.collaborator}/validate/${token}`);

const signUpCollaboratorByToken = (
  collaborator: PartialCollaborator,
  token: string
) => api.post(`${API.collaborator}/validate/${token}`, collaborator);

const fetchTransactions = async ({
  initialDate,
  finalDate,
}: {
  initialDate?: string;
  finalDate?: string;
}) =>
  finalDate && initialDate
    ? api.get(
        `${API.transactions}?initialDate=${initialDate}&finalDate=${finalDate}`
      )
    : api.get(API.transactions);

const fetchBankAccountByRole = async (role: ROLE) =>
  api.get(getUrlByRole(role));
// const fetchProfile = (role) => api.get(getUrlByRole(role).concat(API.profile));

export default {
  addCollaborator,
  fetchCollaboratorEmailByToken,
  signUpCollaboratorByToken,
  createApi,
  login,
  fetchTransactions,
  fetchBankAccountByRole,
};
