import { AnyAction, CombinedState, combineReducers } from 'redux';

import auth from './auth';
import logout from './logout';

const allReducers = combineReducers({
  auth,
});

const rootReducer = (
  state: CombinedState<{ auth: any }> | undefined,
  action: AnyAction
) => {
  if (action.type === logout.type) {
    return allReducers(undefined, action);
  }
  return allReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
