/* eslint-disable no-param-reassign */
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { message, Modal } from 'antd';
import { ROLE } from '../../constants/defaultValues';
import { History } from 'history';
import { PartialCollaborator } from '../../routes/admin/collaborator/components/ConfirmUserForm';
import logout from '../logout';

export const initialState: any = {
  isLoading: false,
  tokens: null,
  error: null,
  email: null,
  role: null,
  firstName: null,
  base64image: null,
  balance: null,
  id: null,
};

export interface LoginFields {
  email: string;
  password: string;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (
      state,
      // eslint-disable-next-line camelcase
      {
        payload: {
          email,
          access_token,
          refresh_token,
          role,
          id,
          base64image,
          firstName,
        },
      }
    ) => {
      state.email = email;
      state.role = role;
      state.id = id;
      state.base64image = base64image;
      state.firstName = firstName;
      state.tokens = { access_token, refresh_token };
    },
    loginRefresh: (
      state,
      // eslint-disable-next-line camelcase
      { payload: { access_token, refresh_token } }
    ) => {
      state.tokens = { access_token, refresh_token };
    },
    loginError: (state, action) => {
      state.error = action.payload.message;
    },
    finishLogin: (state) => {
      state.isLoading = false;
    },
    fetchBalance: (state, { payload }) => {
      state.balance = payload.balance;
    },
  },
});

const { reducer } = authSlice;

export const { actions, name } = authSlice;

export const loginUser = (
  user: LoginFields,
  history: { push: (route: any) => void }
) => async (
  dispatch: Dispatch,
  _: any,
  api: {
    login: (user: LoginFields) => PromiseLike<{ data: any }> | { data: any };
    fetchBankAccountByRole: (
      role: ROLE
    ) => PromiseLike<{ data: { balance: number } }>;
  }
) => {
  dispatch(actions.login());
  try {
    const { data } = await api.login(user);

    if (
      data.role === ROLE.ADMINISTRATOR ||
      (data.isFull === true && data.role === ROLE.COLLABORATOR)
    ) {
      const response = await api.fetchBankAccountByRole(data.role);

      dispatch(
        actions.loginSuccess({
          email: user.email,
          role: data.role,
          id: data.id,
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          base64image: data?.base64image ?? null,
          firstName: data?.firstName ?? null,
        })
      );
      dispatch(actions.fetchBalance({ balance: response.data.balance }));
      history.push('/admin');
    } else {
      message.info('Usuario sin permisos para ingresar');
    }
  } catch (err) {
    dispatch(actions.loginError(err));
  } finally {
    dispatch(actions.finishLogin());
  }
};

export const signUpCollaborator = (
  collaborator: PartialCollaborator,
  history: History,
  token: string
) => async (
  dispatch: Dispatch,
  _: any,
  api: {
    signUpCollaboratorByToken: (
      arg0: PartialCollaborator,
      arg1: string
    ) => PromiseLike<{ data: any }> | { data: any };
  }
) => {
  dispatch(actions.login());
  try {
    const { data } = await api.signUpCollaboratorByToken(collaborator, token);

    if (
      data?.role === ROLE.ADMINISTRATOR ||
      (data?.isFull === true && data?.role === ROLE.COLLABORATOR)
    ) {
      dispatch(
        actions.loginSuccess({
          email: collaborator.email,
          role: data.role,
          id: data.id,
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          base64image: data?.base64image ?? null,
        })
      );
      message.success('Usuario guardado correctamente');
      history.push('/admin');
    } else {
      Modal.success({
        title: 'Cuenta creada correctamente',
        centered: true,
        content: 'Descarga la app para empezar a cobrar y pagar con PayGoal',
        okButtonProps: { style: { display: 'none' } },
      });
      dispatch(logout());
      // history.push('/admin');
    }
  } catch (err) {
    dispatch(actions.loginError(err));
  } finally {
    dispatch(actions.finishLogin());
  }
};

export default reducer;
