import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import reducers from './reducers';
import api from '../api';
import localforage from 'localforage';
import { useDispatch } from 'react-redux';

const persistConfig = {
  key: 'root',
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const initStore = () => {
  let store: any = null;

  api.createApi(() => store);
  store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk.withExtraArgument(api)],
  });
  return store;
};

export const store = initStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
