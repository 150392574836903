import axios, { AxiosRequestConfig } from 'axios';
import { message } from 'antd';

const apiAxios = axios.create({});

const successStatus = (status: number) => status >= 200 && status < 300;

apiAxios.defaults.timeout = Number(process.env.TIMEOUT_TO_FAIL_REQUEST);
apiAxios.defaults.validateStatus = (status) => successStatus(status);

const handleError = (error: {
  response: {
    data: { statusCode: number; message: string | undefined; status: any };
  };
  request: any;
  message: any;
}) => {
  if (
    error?.response?.data?.statusCode >= 400 &&
    (error?.response?.data?.message || error?.response?.data?.status)
  ) {
    if (!error.response.data.status) {
      message.error(error.response.data.message ?? '');
    }

    throw new Error(error.response.data.message);
  } else if (error.request) {
    console.log(error.request);

    message.error('Hubo un problema con la conexión, intente nuevamente');

    throw new Error('Hubo un problema con la conexión, intente nuevamente');
  } else {
    console.log(error);
    message.error(error?.message ?? 'No se pudo completar la solicitud');
    throw new Error(error?.message ?? 'No se pudo completar la solicitud');
  }
};

const get = async (url: string, options?: AxiosRequestConfig | undefined) => {
  try {
    return await apiAxios.get(url, options);
  } catch (error) {
    return handleError(error);
  }
};

const post = async (
  url: string,
  body: any,
  options?: AxiosRequestConfig | undefined
) => {
  try {
    return await apiAxios.post(url, body, options);
  } catch (error) {
    return handleError(error);
  }
};
const patch = async (
  url: string,
  body: any,
  options: AxiosRequestConfig | undefined
) => {
  try {
    return await apiAxios.patch(url, body, options);
  } catch (error) {
    return handleError(error);
  }
};

const del = (url: string, body: AxiosRequestConfig | undefined) =>
  apiAxios.delete(url, body);

const put = async (url: string, body: any) => {
  try {
    return await apiAxios.put(url, body);
  } catch (error) {
    return handleError(error);
  }
};

export default {
  apiAxios,
  get,
  patch,
  post,
  del,
  put,
};
