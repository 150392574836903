import { Button, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

function CollaboratorList() {
  return (
    <div style={{ padding: 24, minHeight: 360 }}>
      <Row></Row>
      <Button type="primary">
        <Link to="/admin/collaborator/add">Agregar colaborador</Link>
      </Button>
    </div>
  );
}

export default CollaboratorList;
