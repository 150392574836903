import React from 'react';
import { Menu, Layout, Avatar } from 'antd';
import {
  UsergroupAddOutlined,
  SlidersOutlined,
  // MenuUnfoldOutlined,
  LogoutOutlined,
  UserOutlined,
  // MenuFoldOutlined,
} from '@ant-design/icons';
import logout from '../../../redux/logout';

import '../index.css';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import makeSelectLogin from '../../../redux/auth/selector';
import { useSelector } from 'react-redux';

const { Content, Sider, Header } = Layout;

function AppLayout({ children }: { children: Partial<Element> }) {
  // const [collapsed, setCollapsed] = useState(false);
  const { base64image, firstName } = useSelector(makeSelectLogin());
  const dispatch = useDispatch();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsedWidth={81}
        width={200}
        breakpoint="lg"
        collapsible
        defaultCollapsed={true}>
        <Menu theme="dark" mode="vertical" style={{ paddingTop: '230px' }}>
          <Menu.Item key="adminMovements" icon={<SlidersOutlined size={100} />}>
            <Link to="/admin/movements">Movimientos</Link>
          </Menu.Item>
          {/* <Menu.Item
            key="adminCollaborator"
            icon={<UsergroupAddOutlined size={100} />}>
            <Link to="/admin/collaborator">Colaboradores</Link>
          </Menu.Item> */}
          <Menu.Item
            key="adminAddCollaborator"
            icon={<UsergroupAddOutlined size={100} />}>
            <Link to="/admin/collaborator/add">Colaboradores</Link>
          </Menu.Item>
          <Menu.Item
            key="logout"
            onClick={() => dispatch(logout())}
            icon={<LogoutOutlined />}>
            Cerrar Sesión
          </Menu.Item>
        </Menu>
        {/* {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: 'ant-layout-sider-trigger',
            style: { width: collapsed ? 81 : 200 },
            onClick: () => setCollapsed(!collapsed),
          }
        )} */}
      </Sider>

      <Layout className="site-layout">
        <Header className="site-layout-background site-layout-header">
          <span>
            <span style={{ marginRight: '7px' }}>
              {firstName ? `Hola, ${firstName}` : '¡Bienvenido!'}
            </span>
            {base64image ? (
              <Avatar src={base64image} />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}
          </span>
        </Header>
        <Content className="site-layout-content">{children}</Content>
        {/* <Footer style={{ textAlign: 'center' }}>PayGoal ©2021</Footer> */}
      </Layout>
    </Layout>
  );
}

export default AppLayout;
