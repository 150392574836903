import { Col, DatePicker, Empty, PageHeader, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import api from '../../api';
import { TRANSACTION_TYPES } from '../../constants/defaultValues';
import moment from 'moment';
import {
  CreditCardOutlined,
  DollarCircleOutlined,
  QrcodeOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import makeSelectLogin from '../../redux/auth/selector';

import localePicker from 'antd/es/date-picker/locale/es_ES';
import Numeral from 'numeral';
import { ColumnsType } from 'antd/lib/table';

const columns: ColumnsType<[]> = [
  {
    title: null,
    align: 'left',
    width: 1,
    dataIndex: 'icon',
  },
  {
    title: 'ACTIVIDAD',
    align: 'left',
    width: 150,
    dataIndex: 'type',
  },
  {
    title: 'FECHA',
    align: 'center',
    dataIndex: 'createdAt',
  },
  {
    title: 'MONTO',
    align: 'right',
    width: 150,
    dataIndex: 'amount',
  },
];

const { RangePicker } = DatePicker;

const numberFormatter = (number: number) => Numeral(number).format('$0,0.00');
const getTransactionIcon = (type: TRANSACTION_TYPES, size: number) => {
  const style = { fontSize: size + 'px', color: '#C0C0BF' };
  switch (type) {
    case TRANSACTION_TYPES.DEPOSIT:
      return <WalletOutlined style={style} />;
    case TRANSACTION_TYPES.WITHDRAWAL:
      return <CreditCardOutlined style={style} />;
    case TRANSACTION_TYPES.INTERNAL:
      return <QrcodeOutlined style={style} />;
    case TRANSACTION_TYPES.INTERNAL_CASH:
      return <DollarCircleOutlined style={style} />;
    default:
      return <WalletOutlined style={style} />;
  }
};

const getTransactionCaption = (type: TRANSACTION_TYPES) => {
  switch (type) {
    case TRANSACTION_TYPES.DEPOSIT:
      return 'Ingreso de dinero';
    case TRANSACTION_TYPES.WITHDRAWAL:
      return 'Extracción';
    case TRANSACTION_TYPES.INTERNAL:
      return 'Cobraste con QR';
    case TRANSACTION_TYPES.INTERNAL_CASH:
      return 'Cobro en efectivo';
    default:
      return 'Movimiento';
  }
};
// {
//   "status": "COMPLETED",
//   "amount": 1,
//   "createdAt": "2021-03-12T21:52:53.788Z",
//   "concept": "Prueba",
//   "invoice": "",
//   "type": "INTERNAL_CASH",
//   "isIncoming": true,
//   "balance": {}
// }

interface DataType {
  key: string;
  icon: JSX.Element;
  type: string;
  createdAt: string;
  amount: string;
}

const processTransactionsToTable = (
  data: {
    isIncoming: boolean;
    amount: number;
    createdAt: Date;
    type: TRANSACTION_TYPES;
  }[]
): DataType[] =>
  data.map((d, i) => {
    return {
      key: i.toString(),
      amount: d.isIncoming
        ? '+' + numberFormatter(d.amount)
        : '-' + numberFormatter(d.amount),
      createdAt: moment(d.createdAt).format('DD-MM-YYYY'),
      type: getTransactionCaption(d.type),
      icon: getTransactionIcon(d.type, 25),
    };
  });

function MovementsPage() {
  const [transactions, setTransactions] = useState([]);
  const { balance } = useSelector(makeSelectLogin());
  const [dateInterval, setDateInterval] = useState([
    moment().startOf('month'),
    moment().endOf('month'),
  ]);

  useEffect(() => {
    const initialDate = dateInterval
      ? moment(dateInterval[0]).toISOString()
      : undefined;
    const finalDate = dateInterval
      ? moment(dateInterval[1]).toISOString()
      : undefined;

    api
      .fetchTransactions({ initialDate, finalDate })
      .then((res) => {
        setTransactions(processTransactionsToTable(res.data) as never[]);
      })
      .catch((err) => console.log(err));
  }, [dateInterval]);

  const handleDatePick = (values: any) => {
    setDateInterval(values);
  };

  return (
    <>
      <Row justify="center">
        <Col span={22}>
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <PageHeader
                title={
                  <p>
                    Disponible en cuenta:{' '}
                    {balance >= 0 ? numberFormatter(balance) : ''}
                  </p>
                }
                className="site-page-header"
              />
            </Col>
            <Col span={24}>
              <RangePicker
                locale={localePicker}
                clearIcon={false}
                format="DD/MM/YYYY"
                defaultValue={[
                  moment().startOf('month'),
                  moment().endOf('month'),
                ]}
                onChange={handleDatePick}
                ranges={{
                  Hoy: [moment().startOf('day'), moment().add(1, 'day')],
                  'Este mes': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                }}
              />
            </Col>
            <Col span={24}>
              <Table
                locale={{
                  emptyText: <Empty description="Sin movimientos" />,
                }}
                size="small"
                columns={columns}
                dataSource={transactions}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default MovementsPage;
