import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import makeSelectLogin from '../redux/auth/selector';

import Spinner from './../components/Spinner';

export default function PrivateRoute(props: any) {
  const { tokens, isLoading } = useSelector(makeSelectLogin());
  const { component: Component, ...rest } = props;
  if (isLoading) {
    return <Spinner />;
  }
  if (tokens) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to="/login" />;
}
