import React from 'react';
import { ErrorMessage, Formik } from 'formik';
import { Input, Button, Row, Col } from 'antd';
import './index.css';
import logo from '../../assets/img/logo-paygoal-v1.jpg';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import makeSelectLogin from '../../redux/auth/selector';
import { LoginFields, loginUser } from '../../redux/auth';
import ErrorComponent from '../../components/ErrorMessage';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('E-mail requerido'),
  password: Yup.string().required('Contraseña requerida'),
});

const LoginPage: React.FC<any> = ({ history }) => {
  const initialValues: LoginFields = { email: '', password: '' };

  const dispatch = useDispatch();
  const { isLoading } = useSelector(makeSelectLogin());

  const handleLogin = (values: LoginFields) => {
    dispatch(loginUser(values, history));
  };

  return (
    <div className="login-container">
      <div className="login-container-app">
        <Row
          justify="center"
          align="middle"
          className="login-content-container">
          <Col span={24} className="avatar-container">
            <img src={logo} height={200} width={200} />
          </Col>

          <Col span={24} className="text-containter">
            <Col>¡Bienvenido!</Col>
            <Col>
              <b>Iniciar Sesión</b>
            </Col>
          </Col>
          <Formik
            initialValues={initialValues}
            onSubmit={handleLogin}
            validationSchema={loginSchema}>
            {({
              setFieldTouched,
              setFieldValue,
              isValid,
              handleSubmit,
              values,
            }: any) => (
              <>
                <Col span={24} className="input-container">
                  <Input
                    className="input-style"
                    placeholder="E-mail"
                    onChange={(evt) => {
                      setFieldValue('email', evt.target.value);
                    }}
                    onBlur={() => setFieldTouched('email')}
                    contentEditable={!isLoading}
                  />
                  <ErrorMessage component={ErrorComponent} name="email" />
                </Col>

                <Col span={24} className="input-container">
                  <Input.Password
                    className="input-style"
                    placeholder="Contraseña"
                    value={values.password}
                    visibilityToggle={false}
                    onChange={(evt) => {
                      setFieldValue('password', evt.target.value);
                    }}
                    onBlur={() => setFieldTouched('password')}
                    contentEditable={!isLoading}
                  />
                  <ErrorMessage component={ErrorComponent} name="password" />
                </Col>
                <Col span={24} className="button-container">
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    block
                    loading={isLoading}
                    disabled={!isValid}
                    className="button-style">
                    Ingresar
                  </Button>
                </Col>
              </>
            )}
          </Formik>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;
