import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import CollaboratorList from './collaborator/CollaboratorList';
import CollaboratorAdd from './collaborator/CollaboratorAdd';
import AppLayout from './layout';
import MovementsPage from './MovementsPage';

function AppRouter() {
  return (
    <AppLayout>
      <Switch>
        <Route path="/admin/collaborator/add" component={CollaboratorAdd} />
        <Route path="/admin/collaborator" component={CollaboratorList} />
        <Route path="/admin/movements" component={MovementsPage} />
        <Redirect to="/admin/movements" />
      </Switch>
    </AppLayout>
  );
}

export default AppRouter;
