import { createSelector } from '@reduxjs/toolkit';
import { initialState, name } from './index';

const loginDomain = (state: { [x: string]: unknown }) =>
  state[name] || initialState;

const makeSelectLogin = () =>
  createSelector(loginDomain, (substate) => substate);

export default makeSelectLogin;
