import { PageHeader, Row, Col, Input, Button } from 'antd';
import { ErrorMessage, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import ErrorComponent from '../../../../components/ErrorMessage';
import * as Yup from 'yup';
import api from '../../../../api';
import { signUpCollaborator } from '../../../../redux/auth';
import { useDispatch } from 'react-redux';
import { History } from 'history';

const collaboratorSchema = Yup.object().shape({
  firstName: Yup.string().required('Nombre es requerido'),
  lastName: Yup.string().required('Apellido es requerido'),
  password: Yup.string()
    .required('Contraseña requerida')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g,
      'Mínimo de 8 caracteres, una letra y un número'
    ),
});

export interface PartialCollaborator {
  firstName: string;
  lastName: string;
  password: string;
  email?: string;
}

const initialValues: PartialCollaborator = {
  firstName: '',
  lastName: '',
  password: '',
};

function ConfirmUserForm({
  token,
  history,
}: {
  token: string;
  history: History;
}) {
  const [email, setEmail] = useState('E-mail');
  const dispatch = useDispatch();
  useEffect(() => {
    api
      .fetchCollaboratorEmailByToken(token)
      .then((res) => setEmail(res.data.email))
      .catch((err) => {
        setTimeout(() => {
          window.location.href = '/admin';
        }, 3000);
        console.log(err);
      });
  }, []);

  const handleAddUser = (values: PartialCollaborator) => {
    dispatch(signUpCollaborator({ ...values, email }, history, token));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleAddUser}
      validationSchema={collaboratorSchema}>
      {({
        setFieldTouched,
        setFieldValue,
        isValid,
        values,
        handleSubmit,
      }: any) => (
        <>
          <PageHeader
            className="site-page-header"
            subTitle="Completá tus datos"
          />
          <Row justify="start" gutter={[20, 20]}>
            <Col flex="start" span={12}>
              Nombre
              <Input
                value={values.firstName}
                onBlur={() => setFieldTouched('firstName')}
                onChange={(e) => setFieldValue('firstName', e.target.value)}
                placeholder="Nombre"
                type="text"
              />
              <ErrorMessage component={ErrorComponent} name="firstName" />
            </Col>
            <Col span={12}>
              Apellido:
              <Input
                value={values.lastName}
                onBlur={() => setFieldTouched('lastName')}
                onChange={(e) => setFieldValue('lastName', e.target.value)}
                placeholder="Apellido"
                type="text"
              />
              <ErrorMessage component={ErrorComponent} name="lastName" />
            </Col>
            <Col span={12}>
              Usuario:
              <Input disabled placeholder={email} />
              <ErrorMessage component={ErrorComponent} name="email" />
            </Col>
            <Col span={12}>
              Contraseña:
              <Input
                value={values.description}
                onBlur={() => setFieldTouched('password')}
                onChange={(e) => setFieldValue('password', e.target.value)}
                placeholder="Contraseña"
                type="password"
              />
              <ErrorMessage component={ErrorComponent} name="password" />
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={!isValid}>
                  Crear Cuenta
                </Button>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
}

export default ConfirmUserForm;
