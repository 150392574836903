import React from 'react';
import { useParams } from 'react-router';
import ConfirmUserForm from './components/ConfirmUserForm';
import { History } from 'history';

function CollaboratorConfirm({ history }: { history: History }) {
  const { uuid }: { uuid: string } = useParams();
  return (
    <div className="collaborator-confirm-container">
      <div className="collaborator-confirm-container-card">
        <ConfirmUserForm token={uuid} history={history} />
      </div>
    </div>
  );
}

export default CollaboratorConfirm;
