import React from 'react';
import { Spin } from 'antd';
import './index.css';
import { LoadingOutlined } from '@ant-design/icons';
function Spinner() {
  return (
    <div className="spinner-container">
      <Spin indicator={<LoadingOutlined spin />} size="large" />
    </div>
  );
}

export default Spinner;
