import { PageHeader, Row, Col, Card, List, Checkbox } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import logoFull from '../../../../assets/img/icons/shield-full-icon.png';
import logoCobrador from '../../../../assets/img/icons/shield-cobrador-icon.png';
import '../../index.css';
import { useState } from 'react';

function CardPermissions({
  setFieldValue,
}: {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) {
  const [permissionCheckboxClicked, setPermissionCheckboxClicked] = useState(1);

  const handleChangePermission = (e: any) => {
    setPermissionCheckboxClicked(e.target.value);
    setFieldValue('permission', e.target.value);
  };

  return (
    <>
      <PageHeader className="site-page-header" subTitle="Permisos" />
      <Row gutter={[10, 16]}>
        <Col span={12}>
          <Card
            title={
              <>
                <img
                  width="40"
                  style={{ borderRadius: '4px', border: '5px solid #fff' }}
                  height="40"
                  src={logoFull}
                />
                <span className="card-title">Full</span>
              </>
            }
            extra={
              <Checkbox
                onClick={handleChangePermission}
                checked={permissionCheckboxClicked == 1}
                value={1}
              />
            }
            className={
              permissionCheckboxClicked == 1
                ? 'custom-card full active'
                : 'custom-card full'
            }
            bordered>
            <List.Item>
              <List.Item.Meta
                avatar={<CheckCircleTwoTone twoToneColor="#63F2D3" />}
                title="Sin restricciones para operar"
              />
            </List.Item>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={
              <>
                <img
                  width="40"
                  height="40"
                  style={{ borderRadius: '4px', border: '5px solid #fff' }}
                  src={logoCobrador}
                />
                <span className="card-title">Cobrador</span>
              </>
            }
            extra={
              <Checkbox
                onClick={handleChangePermission}
                checked={permissionCheckboxClicked == 2}
                value={2}
              />
            }
            className={
              permissionCheckboxClicked == 2
                ? 'custom-card cobrador active'
                : 'custom-card cobrador'
            }
            bordered>
            <List.Item>
              <List.Item.Meta
                avatar={<CheckCircleTwoTone twoToneColor="#FF9500" />}
                title="Visualiza sus movimientos"
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                avatar={<CloseCircleTwoTone twoToneColor="#FF0000" />}
                title="Visualiza el saldo"
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                avatar={<CloseCircleTwoTone twoToneColor="#FF0000" />}
                title="Pagos"
              />
            </List.Item>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CardPermissions;
