import { Breadcrumb, Col, PageHeader, Row, Button, message } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import CardPermissions from './components/CardPermissions';
import InputForm from './components/InputForm';
import * as Yup from 'yup';
import { History } from 'history';
import api from '../../../api';

// NOMBRE Y APELLIDO DESHABILITADOS, LOS CARGAN EN LA CONFIRMACIÓN (POR AHORA)

const collaboratorSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('E-mail requerido'),
  // firstName: Yup.string().required('Nombre es requerido'),
  // lastName: Yup.string().required('Apellido es requerido'),
});

export interface Collaborator {
  // firstName: string;
  // lastName: string;
  email: string;
  description: string;
  phone: string;
  permission: string;
}

const initialValues: Collaborator = {
  // firstName: '',
  // lastName: '',
  email: '',
  description: '',
  phone: '',
  permission: '1',
};

function CollaboratorAdd({ history }: { history: History }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleAddUser = (collaborator: Collaborator) => {
    setIsLoading(true);
    api
      .addCollaborator(collaborator)
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          history.push('/admin/collaborator');
          message.info('Colaborador creado correctamente', 10);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/admin/collaborator">Colaboradores</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Agregar Colaborador</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader className="site-page-header" title="Agregar colaborador" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleAddUser}
        validationSchema={collaboratorSchema}>
        {({
          setFieldTouched,
          setFieldValue,
          isValid,
          values,
          handleSubmit,
        }: any) => (
          <>
            <Row>
              <Col span={18}>
                <InputForm
                  values={values}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
                <CardPermissions setFieldValue={setFieldValue} />
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row className="buttons-row" justify="end">
              <Button type="text" onClick={() => history.goBack()}>
                Cancelar
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={isLoading}
                disabled={!isValid}>
                Crear Colaborador
              </Button>
            </Row>
          </>
        )}
      </Formik>
    </>
  );
}

export default CollaboratorAdd;
